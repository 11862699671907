import { GetStartedButton } from 'component-library/components/buttons/GetStartedButton';
import { Body } from 'component-library/components/typography/Body';
import { Heading } from 'component-library/components/typography/Heading';
import { Screen, ShowOn } from 'component-library/components/wrappers/ShowOn';
import { SpendingCardHeroSmall } from 'component-library/images/SpendingCardHero';
import React from 'react';
import styled from 'styled-components';

import { body, heading } from './content';

const HeroWrapper = styled(ShowOn)`
  padding: 56px 0 12px;
`;

const Text = styled.div`
  padding: 0 20px;
`;

export const HeroSmall = () => (
  <HeroWrapper screens={[Screen.Sm, Screen.Md]}>
    <Text>
      <Heading marginBottom={16} variant={4}>
        {heading}
      </Heading>
      <Body variant={3} marginBottom={32}>
        {body}
      </Body>
      <GetStartedButton
        buttonText="Create Your Account"
        to="/welcome-card/sign-up"
        marginBottom={56}
      />
    </Text>
    <SpendingCardHeroSmall />
  </HeroWrapper>
);
