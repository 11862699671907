import { getCurrentInterestRateDetails } from 'common/interest-rate-components/base';
import { IconName } from 'component-library/components/icons/Icon';
import { graphql } from 'gatsby';
import { WelcomeCardStepsFragment } from 'graphqlTypes';

export const WelcomeCardSteps = graphql`
  fragment WelcomeCardSteps on Query {
    ...GetCurrentInterestRateDetails
  }
`;

export const welcomeCardHeading =
  'The spending companion to your retirement plan.';

export const getWelcomeCardSteps = (data: WelcomeCardStepsFragment) => [
  {
    iconName: IconName.BoostIncome,
    heading: `Earn ${
      getCurrentInterestRateDetails(data).formattedApy
    } on your cash balance*`,
    body:
      'Monitor your savings in a high-yield account with one of the best APYs in the market.',
  },
  {
    iconName: IconName.KeepMoreMoney,
    heading: 'Ideal for saving or spending',
    body:
      'Unite your income streams into one account for an easier retirement or build an emergency fund before retiring.',
  },
  {
    iconName: IconName.StayOnTrack,
    heading: 'Free Retirement Planning',
    body:
      'Get a custom financial plan built by a licensed fiduciary after your account is open.',
  },
];
