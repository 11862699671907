import { graphql } from 'gatsby';
import { WelcomeCardHeroFragment } from 'graphqlTypes';
import React, { FC } from 'react';

import { HeroLarge } from './HeroLarge';
import { HeroSmall } from './HeroSmall';

export const WelcomeCardHero = graphql`
  fragment WelcomeCardHero on Query {
    ...WelcomeCardHeroLarge
  }
`;

interface HeroProps {
  data: WelcomeCardHeroFragment;
}

export const Hero: FC<HeroProps> = ({ data }) => (
  <>
    <HeroSmall />
    <HeroLarge data={data} />
  </>
);
