import Layout from 'common/layouts/Layout';
import { Navigation } from 'component-library/components/navigation/Navigation';
import { PageWrapper } from 'component-library/components/wrappers/PageWrapper';
import { ManAndWomanWalkingOutside } from 'component-library/images/ManAndWomanWalkingOutside';
import { colors } from 'component-library/styles/colors';
import { SEO } from 'components/SEO';
import { graphql } from 'gatsby';
import { WelcomeCardQuery } from 'graphqlTypes';
import {
  BottomCta,
  FeaturesSection,
  ThreeStepSection,
} from 'page-components/shared';
import { Footer } from 'page-components/shared/footer';
import {
  featuresHeading,
  featuresSections,
} from 'page-components/spending/features/utils';
import { Hero } from 'page-components/welcome-card/hero';
import {
  getWelcomeCardSteps,
  welcomeCardHeading,
} from 'page-components/welcome-card/utils';
import React, { FC } from 'react';

interface WelcomeCardProps {
  data: WelcomeCardQuery;
}

const WelcomeCard: FC<WelcomeCardProps> = ({ data }) => (
  <Layout>
    <Navigation simpleNav />
    <PageWrapper background={colors.greenLight}>
      <Hero data={data} />
      <ThreeStepSection
        heading={welcomeCardHeading}
        image={<ManAndWomanWalkingOutside />}
        steps={getWelcomeCardSteps(data)}
      />
      <FeaturesSection heading={featuresHeading} sections={featuresSections} />
      <BottomCta
        heading="Create your high-yield account in minutes."
        buttonText="Create Your Account"
        to="/welcome-card/sign-up"
      />
      <Footer data={data} displayDepositAccountDetails />
    </PageWrapper>
  </Layout>
);

export default WelcomeCard;

export const Head = () => (
  <SEO
    title="Welcome to Retirable | Retirable"
    description="Welcome to Retirable. The first-of-its-kind planning, investing and spending solution built for retirees to make the most out of their golden years."
    imagePath="welcome-featured.png"
  />
);

export const query = graphql`
  query WelcomeCard {
    ...WelcomeCardHero
    ...WelcomeCardSteps
    ...DepositAccountAgreement
  }
`;
